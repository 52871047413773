<template>
    <div>
        <headerOrder></headerOrder>
        <div class="main">
            <div class="bzj">
                <img src="./wenhao.png" alt="" srcset="">
                <div style="margin-left:20px">
                    当前航线需要支付履约保证金
                </div>
            </div>
            <div class="amountBox">
                 <div style="margin-top:50px">
                    订单类型：<i>履约保证金</i>
                </div>
                <div  style="margin-top:12px">
                    订单号：<i>{{orderInfo.id}}</i>
                </div>
                <div  style="margin-top:12px">
                    费用（USD）：<i>{{orderInfo.depositAmount}}</i>
                </div>
                <div  style="margin-top:12px">
                    收款账户：<i>3301040160018693138</i>
                </div>
                <div  style="margin-top:12px">
                    收款人：<i>杭州物数化科技有限公司</i>
                </div>
                <div  style="margin-top:12px">
                    开户行：<i>杭州银行科技支行</i>
                </div>
                <div  style="margin-top:12px">
                    联系电话：<i>0755-25100671</i>
                </div>
                   <div class="button">
                        <div class="ljzf" @click="uploadpay" >
                            我已打款，上传水单
                        </div>
                        <div class="fh" @click="fy">
                            返回首页
                        </div>
                    </div>
                    <div class="error">
                    履约保证金将会在订单结束7个工作日后原路返还至您的账户，若您在中途取消订单，该履约保证金将不会返还
                    </div>
            </div>
            <!-- <div class="amountBox">
                <div style="margin-top:50px">
                    订单类型：<i>舱位保证金</i>
                </div>
                <div  style="margin-top:12px">
                    订单号：<i>{{orderInfo.id}}</i>
                </div>
                <div  style="margin-top:12px">
                    费用（CNY）：<i>{{orderInfo.depositAmount}}</i>
                </div>
                <div  style="margin-top:30px">
                    请选择支付方式：
                </div>
                <div class="pay">
                    <div @click="selsct('alpay')" class="zhifu" :class="pay == 'alpay' ? 'bakc' :''">
                        <img src="./alpay.png" alt="" srcset="">
                    </div>
                    <div style="margin-left:20px" class="zhifu" :class="pay == 'wechat' ? 'bakc' :''" @click="selsct('wechat')">
                        <img src="./wechat.png" alt="" srcset="">
                    </div>
                </div>
                <div class="button">
                    <div class="ljzf">
                        立即支付
                    </div>
                    <div class="fh" @click="fy">
                        返回上一步
                    </div>
                </div>
                <div class="error">
                  履约保证金将会在订单结束7个工作日后原路返还至您的账户，若您在中途取消订单，该履约保证金将不会返还
                </div>
            </div> -->
        </div>
             <el-dialog
        title="上传水单信息"
        :visible.sync="dialogVisiblebl"
        width="45%"
        :before-close="handleClosebk">
            <div class="flex upload">
                <el-upload
                    class="upload-demo"
                    drag
                    action="https://test.dosbooking.com:8700/system/uploadImg"
                    :on-success="handleSuccess"
                    :limit='1'
                    :on-exceed="onExceed"
                    multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                    <div class="fileListt">
                        <div v-for="(item,index) in fileList" :key="item.id" class="filebox">
                            <div style=" margin-left: 34px;">
                                {{item.name}}
                            </div>
                            <div style="margin-right: 10px;width:24px;height:24px" class="closefile" @click="deltfile(index)">
                                <img src="../index/C.png" alt="" srcset="" style="width:24px;height:24px">
                            </div>
                        </div>
                    </div>
            </div>
            <!-- <el-input v-model="value"></el-input> -->
        <!-- </span> -->
        <span slot="footer" class="dialog-footer1">
            <el-button size="small" type="primary" @click="updateBl" :loading="saveloading">确 定</el-button>
            <el-button @click="dialogVisiblebl = false" size="small">取 消</el-button>
          
        </span>
        </el-dialog>
    </div>
</template>
<script>
import headerOrder from './../common/headerOrder.vue'
export default {
    components:{
        headerOrder
    },
    data(){
        return{
            dialogVisiblebl:false,
            saveloading:false,
            pay:'',
            fileList:[],
            orderInfo:{},
            imgfileList:''
        }
    },
    mounted(){
        this.getData()  
    },
    methods:{
        selsct(type){
            this.pay = type
        },
        uploadpay(){
            this.dialogVisiblebl = true
        },
        handleSuccess(res, file, fileList){
                if(res.code == '200'){
                    this.fileList = fileList
                    this.imgfileList = res.data
                    // this.imgfileList.push(res.data)
                    console.log(this.imgfileList)
                    this.$Notice.success({
                        title: "上传成功",
                    });
                    
                    // this.initData()
                }
                this.fullscreenLoading = false
            },
        fy(){
            this.$router.push('/index')
            // this.$router.go(-1)
        },
        handleClosebk(){
            this.dialogVisiblebl = false
        },
         getData(){
            console.log(this.$route)
            this.$get('/freight/getFreightOrderDetail4app',{orderId:this.$route.query.orderId}).then(res =>{
                    this.orderInfo = res.data
                    // debugger
            })
        },
          updateBl(){
             if(this.imgfileList == ''){
                    this.$message.error('请上传文件')
                    return false
                }
            let params = {
                orderId:this.$route.query.orderId,
                file:this.imgfileList
            }
            this.$post('/freight/clientUploadPayKcFeeFile',params).then(res =>{
               if(res.code == '200'){
                   this.dialogVisiblebl = false
                    this.$message.success("提交成功，请等待小二审核")
                    // this.searchOrder4App()
               }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.main{
    margin-top:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.bzj{
   width: 1120px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
}
.bzj img{
    width: 35px;
    height: 35px;
}
.amountBox{
    margin-top: 13px;
    width: 1120px;
    height: 415px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
}
.amountBox i{
    font-weight: 500;
}
.pay{
    display: flex;
    margin-top: 27px;
    
}
.pay img{
    cursor: pointer;
   width: 45px;
   height: 45px; 
}
.zhifu{
    width: 74px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bakc{
    
    background: url('./select.png') no-repeat;
    background-size: cover;
}
.button{
    margin-top: 32px;
    display: flex;
}
.ljzf{
    padding: 0 10px;
//     width: 88px;
height: 32px;
background: #1677FF;
border-radius: 4px;
line-height: 32px;
text-align: center;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
cursor: pointer;
}
.fh{
       width: 88px;
height: 32px;
width: 102px;
height: 32px;
border-radius: 4px;
opacity: 0.5;
border: 1px solid #CCCCCC;
border-radius: 4px;
line-height: 32px;
text-align: center;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333; 
margin-left: 24px;
cursor: pointer;
}
.error{
    margin-top: 12px;
    font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #E02020;
line-height: 20px;
}
.filebox{
   width: 272px;
    height: 30px;
    background: #F5F5F5;
    border-radius: 2px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    
}
</style>